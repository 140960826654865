@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap);
.regular-checkbox {
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #cccae8;
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.regular-checkbox:checked {
  background-color: white;
  border: 1px solid #cccae8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
  outline: none;
}

.regular-checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: var(--blue-medium);
}
@media only screen and (max-width: 768px) {
  .regular-checkbox:checked:after {
    top: -2px;
  }
}

.regular-checkbox {
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #cccae8;
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.regular-checkbox:checked {
  background-color: white;
  border: 1px solid #cccae8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
  outline: none;
}

.regular-checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: var(--blue-medium);
}
@media only screen and (max-width: 768px) {
  .regular-checkbox:checked:after {
    top: -2px;
  }
}

/* Font Face */

.container-toast {
    position: absolute;
    z-index: 9999999999999999;
    top: 0;
}

