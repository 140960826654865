.regular-checkbox {
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #cccae8;
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.regular-checkbox:checked {
  background-color: white;
  border: 1px solid #cccae8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
  outline: none;
}

.regular-checkbox:checked:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: var(--blue-medium);
}
@media only screen and (max-width: 768px) {
  .regular-checkbox:checked:after {
    top: -2px;
  }
}
